import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../index.css";
import "./bods.css";
import Nav from "../../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../../components/contact";
import imgHero from "../../images/gfx/hero-bods.jpg"
import imgMobileHero from "../../images/gfx/mobile-hero-bods.jpg"
import Footer from "../../components/footer";
import imgOperator from "../../images/gfx/bods-app.jpg"
import imgSchool from "../../images/gfx/bods-tracker.jpg"
import imgPhone from "../../images/gfx/who-we-help-phone.png"
import imgArrow from "../../images/gfx/who-we-help-arrow.png"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="BODS AVL - Bus Open Data package | Cost-effective vehicle location data (AVL) solution for Bus Open Data compliance" description="An innovative solution to sending vehicle location data to the Bus Open Data Service (BODS), without an expensive ETM." />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-standard" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>Bus Open Data Package</h1>
                <h2>The simple solution for BODS vehicle location data</h2>
              </div>

              <div className="mobile-hero-standard" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#063f82', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>Bus Open Data Package</h1>
                <h2>The simple solution for BODS vehicle location data</h2>
              </div>

              <div className="bods-app__parent">
                <div className="bods-app">
                  <div className="bods-app__text">
                    <h2>Option 1</h2>
                    <h3>Bus Open Data App</h3>
                    <p>Our latest integration helping you comply with Bus Open Data regulations, for <strong>FREE</strong>!</p>
                    <p>This integration is bundled into our affordable ticketing system, powered by a driver app used on a smartphone or tablet device.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/bus-open-data-vehicle-location-package/bods-app/" className="btn">View info and pricing</Link>
                      {/* <Link to="/book-demo/" className="btn">Book a demo</Link> */}
                    </div>
                  </div>
                  <div className="bods-app__img">
                    <img src={imgOperator} alt="" />
                  </div>

                </div>

              </div>

              <div className="bods-tracker__parent">
                <div className="bods-tracker">
                  <div className="bods-tracker__img">
                    <img src={imgSchool} alt="" />
                  </div>
                  <div className="bods-tracker__text">
                    <h2>Option 2</h2>
                    <h3>Bus Open Data Tracker</h3>
                    <p>Our original integration powered by simple fixed or portable vehicle tracking devices.</p>
                    <p>This integration is available standalone or as an add-on to our ticketing system, starting at £13.50 per month.</p>
                    <div>
                      <Link style={{ marginRight: 10 }} to="/bus-open-data-vehicle-location-package/bods-tracker/" className="btn">View info and pricing</Link>
                      {/* <Link to="/book-demo/" className="btn">Book a demo</Link> */}
                    </div>
                  </div>
                </div>

              </div>

              <div className="bods-footer__parent">
                <div className="bods-footer">
                  <div className="bods-footer__img">
                    <img src={imgPhone} alt="" />
                  </div>
                  <div className="bods-footer__text">
                    <h3>Find out more about how our digital bus pass system gives you control and helps you save time and money</h3>
                    <p><Link className="bods-footer__btn" to="/packages/">View all packages <img src={imgArrow} alt="" /></Link></p>
                  </div>
                </div>
              </div>

              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
